// Posts Actions
export const FETCH_ALL = 'FETCH_ALL';
export const CREATE = 'CREATE';
export const EDIT = 'EDIT';
export const DELETE = 'DELETE';
export const DELETE_MANY = 'DELETE_MANY';
export const FETCH_POPULAR = 'FETCH_POPULAR';
export const FETCH_TRENDING = 'FETCH_TRENDING';
export const SAVE_TRENDING = 'SAVE_TRENDING';
export const UNSAVE_TRENDING = 'UNSAVE_TRENDING';

// Counting Pages
export const COUNT_PAGE  = 'COUNT_PAGE';

// Loading Indeterminate Actions
export const LOADING_START = 'LOADING_START';
export const LOADING_END = 'LOADING_END';

// Alert
export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';

// Authentication
export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';

// User Settings
export const SETTINGS = 'SETTINGS'
export const CHANGE_EMAIL = 'CHANGE_EMAIL'
export const BLOCK = 'BLOCK';
export const UNBLOCK = 'UNBLOCK';
export const DEACTIVATE = 'DEACTIVATE';
export const REACTIVATE = 'REACTIVATE';
