import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    container: {
        padding: '40px 0px',
    },
    tagsContainer: {
        padding: '20px 40px',
    },
    title: {
        fontFamily: 'MetropolisBold',
        fontWeight: '700',
        padding: '0px 30px',
    },
    tag: {
        margin: '5px 5px',
        fontSize: '12px'
    },
}));