import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    container: {
        padding: '60px 20px',
    },
    holder: {
        padding: '5px 0',
        display: 'flex',
        alignItems: 'center'
    },
    constraintText: {
        marginLeft: '10px',
    }
}))