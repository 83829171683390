const tags = [
    'Swift',
    'SwiftUI',
    'XCode',
    'Django',
    'Python',
    'Node.js',
    'Express',
    'Android',
    'Java',
    'MongoDB',
    'Angular', 
    'jQuery', 
    'Polymer', 
    'React.js', 
    'Vue.js'
];

export default tags;