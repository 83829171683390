import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    menuItem: {
        fontSize: '14px',
        fontWeight: '300',
        padding: '10px 20px',
    },
    dropDownListContainer: {
      marginTop: '10px',
      zIndex: '1'
    },
    dropDownList: {
      marginTop: '10px',
      padding: '3px',
    },
}));