import React, { useState } from 'react';
import { Container, Grid, Typography, Button, Link, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert'
import { useHistory, useParams } from 'react-router-dom'; 

import useStyles from './styles';
import greenTick from '../../../images/green-tick.png';
import { resendActivation } from '../../../api';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const Success = () => {
    const classes = useStyles();
    const history = useHistory();
    const [successful, setSuccessful] = useState(true);
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    let { email } = useParams();

    const goToLogin = () => {
        history.push('/login');
    }

    const resend = () => {
        resendActivation(email).then(res => {
            setMessage('');
            setSuccessful(true);
            setOpen(true);
        }).catch(error => {
            if(error.response.status === 429){
                setMessage("You cannot send more emails. Please wait at least 5 minutes to send another email");
            }
            setSuccessful(false);
            setOpen(true);
        });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    return (
        <Container className={classes.container}>
            <Grid container justify="center" align="center">
                <Grid item xs={12}>
                    <img className={classes.tickIcon} src={greenTick} alt="Success" height="100" width="100"/>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.message1} variant="h5">Your account has been successfully registered!</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.message2} variant="body1">Thank you for registering</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.message3} variant="body2">Check you inbox and spam folders in your email for activation link. Did not receive an email <Link className={classes.link} onClick={resend} color="primary">resend activaiton email</Link></Typography>
                    <Typography style={{ color: 'red' }} variant="caption">{message}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button className={classes.login} onClick={goToLogin} variant="contained" color="primary">Log in</Button>
                </Grid>
            </Grid>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleClose} severity={successful ? "success" : "error"}>
                    {successful ? 'Email Sent!' : 'Could\'nt send email'}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default Success;